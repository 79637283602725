<template>
  <div>
    <a-tabs default-active-key="all" @change="handleChangeTabs">
      <a-tab-pane key="all">
        <template #tab>
          <a-badge class="iss-badge all" :count="dataTypeTotal.all">
            全部
          </a-badge>
        </template>
      </a-tab-pane>
      <a-tab-pane key="unsent">
        <template #tab>
          <a-badge class="iss-badge" :count="dataTypeTotal.unsent">
            未开始
          </a-badge>
        </template>
      </a-tab-pane>
      <a-tab-pane key="sent">
        <template #tab>
          <a-badge class="iss-badge sent" :count="dataTypeTotal.sent">
            已完成
          </a-badge>
        </template>
      </a-tab-pane>
      <a-tab-pane key="draft">
        <template #tab>
          <a-badge class="iss-badge draft" :count="dataTypeTotal.draft">
            草稿
          </a-badge>
        </template>
      </a-tab-pane>
    </a-tabs>
    <search-form :items="items" @fnSearch="handleFnSearch" />
    <grid
      ref="grid"
      :columns="columns"
      :code="$route.path"
      :url="url"
      :search="search"
      :btn-operation="btnOperation"
      :scroll="{ x: 1500, y: gridHeight }"
    >
      <template v-slot:status="{ record }">
        <a-tag :color="record === '2' ? 'green' : 'orange'">
          {{ record === '2' ? '启用' : '草稿' }}
        </a-tag>
      </template>
      <template v-slot:sendStatus="{ record }">
        <a-tag :color="record ? 'green' : 'orange'">
          {{ record ? '已发送' : '未发送' }}
        </a-tag>
      </template>
      <template v-slot:operation="{ record }">
        <operation :options="options" :record="record" />
      </template>
    </grid>
  </div>
</template>
<script>
import { Tabs, Badge, Tag } from 'ant-design-vue';
import SearchForm from '@/components/searchForm';
import Grid from '@/components/grid';
import Operation from '@/components/operation';
import marketingApi from '@/api/marketing.js';
import { numberFormat } from '@/utils';

export default {
  name: 'Push',
  components: {
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    ABadge: Badge,
    ATag: Tag,
    SearchForm,
    Grid,
    Operation,
  },
  data() {
    return {
      items: [{ key: 'name', label: '名称' }],
      columns: [
        { dataIndex: 'name', title: '名称' },
        {
          dataIndex: 'status',
          title: '状态',
          slots: { customRender: 'status' },
        },
        {
          dataIndex: 'sendStatus',
          title: '发送状态',
          slots: { customRender: 'sendStatus' },
        },
        {
          dataIndex: 'openCount',
          title: '点击量 / 发送量',
          customRender: ({ text, record }) => `${text} / ${record.targetCount}`,
        },
        {
          dataIndex: 'targetCount',
          title: '点击率',
          customRender: ({ text, record }) =>
            `${(record.openCount ?? 0 / text ?? 0) * 100} %`,
        },
        {
          dataIndex: 'sendType',
          title: '发送类型',
          customRender: ({ text, record }) =>
            record.status === '2'
              ? text === '1'
                ? '立即发送'
                : '定时发送'
              : '--',
        },
        {
          dataIndex: 'sendTime',
          title: '发送时间',
          width: 170,
          customRender: ({ text, record }) =>
            record.status === '2'
              ? record.sendType === '1'
                ? text
                : record.jobTime
              : '--',
        },
        { dataIndex: 'updateTime', title: '更新时间', width: 170 },
        {
          key: 'id',
          title: '操作',
          fixed: 'right',
          slots: { customRender: 'operation' },
        },
      ],
      btnOperation: [
        { type: 'add', permission: 'push:add', fnClick: this.edit },
      ],
      options: [
        {
          type: 'update',
          permission: 'push:update',
          show: ({ sendStatus }) => !sendStatus,
          fnClick: this.edit,
        },
        {
          type: 'send',
          icon: 'ToTopOutlined',
          permission: 'push:send',
          show: ({ sendStatus }) => !sendStatus,
          label: '发送',
          fnClick: this.send,
        },
        {
          type: 'delete',
          permission: 'push:delete',
          fnClick: this.delete,
        },
      ],
      search: {},
      url: marketingApi.pushPageUrl,
      gridHeight: document.body.clientHeight - 384,
      dataTypeTotal: {
        all: 0,
        unsent: 0,
        sent: 0,
        draft: 0,
      },

      msgTplDialogLoading: false,
      previewUrl: '',
      dialogTitle: '新增邮件消息',
      msgTplListDialogVisible: false,
      msgTplEditSign: '',
      centerDialogVisible: true,
      addParams: {
        label: 'default label',
        status: true,
        msgId: '',
        name: '',
        addresser: '',
      },
      messageTplOptions: [],
      tableData: [],
      current: 1,
      size: 10,
      total: 0,
      dialogLoading: false,
      loading: false,
      multipleSelection: [],
    };
  },
  filters: {
    numFilter(value) {
      return numberFormat(value);
    },
  },
  // activated() {
  //   this.getList();
  //   this.getMessageTplList();
  // },
  created() {
    this.initDataTypeBadge();
  },
  methods: {
    async initDataTypeBadge() {
      const { total: all } = await marketingApi.getPushPlanList(
        this.$route.path,
        {
          size: 0,
        }
      );
      const { total: unsent } = await marketingApi.getPushPlanList(
        this.$route.path,
        { size: 0, sendStatus: false }
      );
      const { total: sent } = await marketingApi.getPushPlanList(
        this.$route.path,
        { size: 0, sendStatus: true }
      );
      const { total: draft } = await marketingApi.getPushPlanList(
        this.$route.path,
        { size: 0, status: 1 }
      );
      this.dataTypeTotal = { all, unsent, sent, draft };
    },
    handleChangeTabs(activeKey) {
      let temp = {
        unsent: { sendStatus: false },
        sent: { sendStatus: true },
        draft: { status: 1 },
      };
      this.handleFnSearch(temp[activeKey]);
    },
    handleFnSearch(value) {
      this.search = value;
    },
    edit(data) {
      this.$router.push(`/auto/push/${data.id || 'add'}`);
    },
    delete({ id }) {
      marketingApi.delPushPlanItem('push:delete', { ids: [id] }).then(() => {
        this.$message.success(this.$t('tips.deleteSuccess'));
        this.$refs.grid.refreshGrid();
        this.initDataTypeBadge();
      });
    },
    send({ id }) {
      marketingApi.sendPushPlan('push:send', { id }).then(() => {
        this.$message.success('发送成功！');
        this.$refs.grid.refreshGrid();
        this.initDataTypeBadge();
      });
    },

    async getList() {},
    submitUpload() {
      this.$refs.upload.submit();
    },
    async tplManage() {
      this.msgTplListDialogVisible = true;
    },

    msgTplPreviewFn(row) {
      open(row.url);
    },

    async getMessageTplList() {
      this.msgTplDialogLoading = true;
      const res = await marketingApi.getMessageTplList();
      this.msgTplDialogLoading = false;
      if (!res) return;
      this.messageTplOptions = res.records;
    },

    addMailItem() {
      this.dialogTitle = '新增邮件消息';
      this.sendParams();
    },

    async sendParams() {
      // 通过id判断是否为编辑
      const httpType = this.addParams.id ? 'editMailItem' : 'addMailItem';
      const notifyText = this.addParams.id ? '修改' : '新增';

      this.dialogLoading = true;
      const res = await marketingApi[httpType](this.addParams);
      this.dialogLoading = false;

      this.$notify({
        title: res ? '成功' : '失败',
        message: res ? notifyText + '成功！' : notifyText + '失败！',
        type: res ? 'success' : 'error',
      });
      // 请求失败终止执行
      if (!res) return;

      // 关闭对话框  刷新列表
      this.centerDialogVisible = false;
      this.getList();
    },

    // 取消新增/修改
    addCancel() {
      this.centerDialogVisible = false;
    },

    // 删除推送计划

    // 修改邮件消息
    async editGift(tplData) {
      this.centerDialogVisible = true;
      this.dialogTitle = '修改邮件消息';
      this.$nextTick(function () {
        Object.assign(this.addParams, tplData);
      });
    },

    // 更新消息模板
    async addOreditMsgTplItem(row) {
      const methodType =
        row.type === 'add' ? 'addMessageItem' : 'editMessageItem';
      const msgType = row.type === 'add' ? '新增' : '修改';

      this.msgTplDialogLoading = true;
      const res = await marketingApi[methodType](this.msgTplParams);
      this.msgTplDialogLoading = false;

      if (!res) return;

      this.$notify({
        title: '成功',
        message: msgType + '成功！',
        type: 'success',
      });

      this.msgTplParams = {
        name: '',
        url: '',
        label: 'default label',
      };

      // 判断是否为本地资源，是： 则上传后传参， 否：未修改，直接传参
      // const urlChangeStatus = this.msgTplParams.url.includes('blob:http');
      // urlChangeStatus ? this.$refs.upload.submit() : console.log(123);
      this.getMessageTplList();
      this.msgTplEditSign = '';
    },

    msgTplCancelEdit() {
      this.msgTplEditSign = '';
    },

    addMsgTpl() {
      const tempId = +new Date();
      this.messageTplOptions.push({
        id: tempId,
        name: '',
        url: '',
        label: 'default label',
        type: 'add',
      });

      this.msgTplParams = {
        name: '',
        url: '',
        label: 'default label',
      };

      this.msgTplEditSign = tempId;
    },
  },
};
</script>
<style lang="less" scoped>
.iss-badge {
  /deep/ .ant-badge-count {
    right: -7px;
    margin-top: -4px;
  }
  &.all /deep/ .ant-badge-count {
    background-color: @primary-color;
  }
  &.sent /deep/ .ant-badge-count {
    background-color: #52c41a;
  }
  &.draft /deep/ .ant-badge-count {
    background-color: #d9d9d9;
  }
}
</style>
